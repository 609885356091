import {
    Badge,
    Button,
    Collapse,
    Modal,
    Slider,
    Space,
    Tag,
    Tooltip,
    Radio,
    DatePicker,
    Calendar,
    Row,
    Col,
    Divider,
    Descriptions,
    Typography,
    Select as AntSelect,
    Skeleton,
    message,
    Checkbox,
    Tree,
    Popover, Flex,
} from "antd";
import {
    ApartmentOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    FilterOutlined,
    QuestionOutlined,
    StarOutlined,
    TagOutlined,
    SaveOutlined,
    ClockCircleOutlined,
    UploadOutlined,
    LoadingOutlined,
    ExpandOutlined,
    ColumnWidthOutlined,
    CalendarOutlined,
    EyeOutlined,
    ArrowsAltOutlined,
    PlusOutlined,
    CloudUploadOutlined,
    PushpinOutlined,
    ReconciliationOutlined,
    AlertOutlined,
    EditOutlined,
    CloudServerOutlined,
    EyeInvisibleOutlined,
    FlagOutlined,
    BookOutlined,
    ContainerOutlined,
    DownloadOutlined,
    ProfileOutlined,
    ShoppingCartOutlined, LogoutOutlined, CloudDownloadOutlined, LockOutlined, UnlockOutlined, FileTextOutlined
} from "@ant-design/icons";
import FileOutlined from "@ant-design/icons/lib/icons/FileOutlined";
import InfoOutlined from "@ant-design/icons/lib/icons/InfoOutlined";
import {
    AspectLandscapeIcon,
    AspectPanoramaIcon,
    AspectPortraitIcon,
    AspectSquareIcon, BlockDownload, BlockView, CollectionShareIcon, CustomMetaIcon, FrameIcon, FrameStatusIcon, GreyBadge, LibraryIcon,
    RefreshIcon,
    RightsIcon, StorageFolderIcon, TaxonomyIcon, ThumbsDownIcon, ThumbsUpIcon
} from "../helpers/icons";
import React, {useEffect, useState, useRef, useContext, createContext} from "react";
import {Formik} from "formik";
import {useStorageState} from "react-storage-hooks";
import ExploreTaxonomies from "./ExploreTaxonomies";
import ExploreRights from "./ExploreRights";
import {useAggsState} from "../../contexts/AggsContext";
import {useFilters} from "../helpers/useFilters";
import HelpPopover from "../HelpPopover";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import User from "../helpers/User";
import {useSelectedAggsState} from "../../contexts/SelectedAggsContext";
import CreatorTagList from "./CreatorTagList";
import {useAbility} from "@casl/react";
import {AbilityContext, Can} from "../helpers/Can";
import moment from "moment";
import {EditableTag} from "../widgets/TagSelect";
import useCurrentUser from "../helpers/useCurrentUser";
import {Form, Input, Select, FormItem} from "formik-antd";
import api from "../api";
import FullCalendar from "../widgets/FullCalendar";
import {isMobile} from "device-detect";
import {SessionContext} from "../../contexts/SessionContext";
import UploadsTree from "./UploadsTree";
import {RequiredIcon} from "../widgets/EditAssetTable";
import {useAssetLoader} from "./AssetsManager";
import useCurrentOrg from "../helpers/useCurrentOrg";
import VerticalSpace from "../helpers/VerticalSpace";
import DateRangePicker from "../widgets/DateRangePicker";
import AssetGroupChooser, {AssetGroupBulkJobProgress} from "../manage/AssetGroupChooser";
import {useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useAssetGroupState} from "../../contexts/AssetGroupContext";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom-v5-compat";
import {useSelectedAssetsState} from "@/contexts/SelectedAssetsContext";
import {useDrop} from "react-dnd";
import _ from "lodash";
import {useBulkJobsDispatch} from "@/contexts/BulkJobsContext";

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

export default ({collapsed})=>{
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()

    const [activeSidebarFilterKeys, setActiveSidebarFilterKeys] = useStorageState(sessionStorage, `activeSidebarFilterKeys`, []);

    const [refreshTaxonomy, setRefreshTaxonomy] = useState(0)
    const clickRefreshTaxonomy = (e)=> {
        e.preventDefault()
        e.stopPropagation()
        setRefreshTaxonomy(refreshTaxonomy + 1)
    }

    const [refreshEvents, setRefreshEvents] = useState(0)
    const clickRefreshEvents = (e)=> {
        e.preventDefault()
        e.stopPropagation()
        setRefreshEvents(refreshEvents + 1)
    }

    const [refreshCapturedAt, setRefreshCapturedAt] = useState(0)
    const clickRefreshCapturedAt = (e)=> {
        e.preventDefault()
        e.stopPropagation()
        setRefreshCapturedAt(refreshCapturedAt + 1)
    }

    const {aggsLoader} = useAssetLoader()

    const [refreshFilters, setRefreshFilters] = useState(0)
    const clickRefreshFilters = (e)=> {
        e?.preventDefault()
        e?.stopPropagation()
        setRefreshFilters(refreshFilters + 1)
        refresh()
    }

    const refresh = ()=>{
        aggsLoader()
    }

    const maxHeight = '88vh'

    const TaxonomiesTitle = <Space><ApartmentOutlined/><strong>{t('tag-trees','Tag Trees')}</strong></Space>
    const TaxonomiesExtra = (
        <Space size={2}>
            <Tooltip title={t('refresh','Refresh')}>
                <Button size='small' onClick={clickRefreshTaxonomy} type={'text'} shape={'circle'}>
                    <RefreshIcon/>
                </Button>
            </Tooltip>

            <HelpPopover code={'taxonomy-collapse-header'} generalAndAdmin/>
        </Space>
    )
    const TaxonomiesContent = <ExploreTaxonomies refresh={refreshTaxonomy}/>;

    const TaxonomiesPanel = {
        key: 'taxonomy-panel',
        id: 'taxonomy-panel',
        label: TaxonomiesTitle,
        extra: TaxonomiesExtra,
        children: TaxonomiesContent
    }

    const TaxonomiesButton = (
        <Popover
            getPopupContainer={e => e.parentElement}
            overlayStyle={{minWidth:300}}
            trigger={['hover','click']}
            content={<div style={{maxHeight, overflow:'scroll'}}>{TaxonomiesContent}</div> }
            title={<Flex justify={'space-between'}>{TaxonomiesTitle} {TaxonomiesExtra}</Flex>}
            placement={'right'}
        >
            <Button shape={'circle'} icon={<TaxonomyIcon/>} type={'text'} className={'sidebar-btn'}/>
        </Popover>
    )

    const DateCreatedTitle = (
            <Space>
                <ClockCircleOutlined/><strong>{t('date-created','Date Created')}</strong>
                {!_.isEqual(filters.captured_at, []) && <CheckOutlined style={{marginLeft:'1em'}}/>}
            </Space>
        )

    const DateCreatedExtra = (
        <Space size={2}>
            <Button size='small' onClick={clickRefreshCapturedAt} type={'text'} shape={'circle'}>
                <RefreshIcon/>
            </Button>

            <HelpPopover code={'date-created-collapse-header'} generalAndAdmin/>
        </Space>
    )

    const DateCreatedContent = (
        <>
            <DateFilter field={'captured_at'} dragDrop refresh={refreshCapturedAt}/>

            <Divider size={'small'}/>

            <MissingCapturedAtFilter/>
        </>
    )

    const DateCreatedButton = (
        <Popover
            getPopupContainer={e => e.parentElement}
            overlayStyle={{minWidth:300}}
            trigger={['hover','click']}
            content={<div style={{maxHeight, overflow:'scroll', padding:5}}>{DateCreatedContent}</div> }
            title={<Flex justify={'space-between'}>{DateCreatedTitle} {DateCreatedExtra}</Flex>}
            placement={'right'}
        >
            <Button shape={'circle'} icon={<ClockCircleOutlined/>} type={'text'} className={'sidebar-btn'}/>
        </Popover>
    )

    const DateCreatedPanel = {
        key: 'explore-captured-at-panel',
        id: 'explore-captured-at-panel',
        label: DateCreatedTitle,
        extra: DateCreatedExtra,
        children: DateCreatedContent
    }

    const FiltersTitle = <Space><FilterOutlined/><strong>{t('filters','Filters')}</strong></Space>
    const FiltersExtra = (
        <Tooltip title={'Refresh Filters'}>
            <Button size='small' onClick={clickRefreshFilters} type={'text'} shape={'circle'}>
                <RefreshIcon/>
            </Button>
        </Tooltip>
    )
    const FiltersContent = <Filters key={refreshFilters} refresh={refresh} clickRefreshFilters={clickRefreshFilters}/>

    const FiltersButton = (
        <Popover
            getPopupContainer={e => e.parentElement}
            trigger={['hover','click']}
            overlayStyle={{minWidth:400}}
            content={<div style={{maxHeight, overflow:'scroll'}}>{FiltersContent}</div> }
            title={<Flex justify={'space-between'}>{FiltersTitle} {FiltersExtra}</Flex>}
            placement={'right'}
        >
            <Button shape={'circle'} icon={<FilterOutlined/>} type={'text'} className={'sidebar-btn'}/>
        </Popover>
    )

    const FiltersPanel = {
        key: 'explore-filters-panel',
        id: 'explore-filters-panel',
        label: FiltersTitle,
        extra: FiltersExtra,
        children: FiltersContent
    }

    const EventsTitle = <Space><CalendarOutlined/><strong>{t('events','Events')}</strong></Space>
    const EventsExtra = (
        <Space size={2}>
            <Tooltip title={t('refresh','Refresh')}>
                <Button size='small' onClick={clickRefreshEvents} type={'text'} shape={'circle'}>
                    <RefreshIcon/>
                </Button>
            </Tooltip>

            <HelpPopover code={'taxonomy-collapse-header'} generalAndAdmin/>
        </Space>
    )

    const EventsContent = <Events refreshEvents={refreshEvents} setRefreshEvents={setRefreshEvents}/>

    const EventsButton = (
        <Popover
            getPopupContainer={e => e.node}
            trigger={['hover','click']}
            content={<div style={{maxHeight, overflow:'scroll'}}>{EventsContent}</div> }
            title={<Flex justify={'space-between'}>{EventsTitle} {EventsExtra}</Flex>}
            placement={'right'}
            overlayStyle={{maxWidth:400, minWidth:300}}
        >
            <Button shape={'circle'} icon={<CalendarOutlined/>} type={'text'} className={'sidebar-btn'}/>
        </Popover>
    )

    const EventsPanel = {
        key: 'events',
        id: 'events',
        label: EventsTitle,
        extra: EventsExtra,
        children: EventsContent
    }

    const components = {
        taxonomies: TaxonomiesPanel,
        date_created: DateCreatedPanel,
        events: EventsPanel,
        filters: FiltersPanel,
    }

    const collapsedComponents = {
        taxonomies: TaxonomiesButton,
        date_created: DateCreatedButton,
        events: EventsButton,
        filters: FiltersButton,
    }

    if(collapsed) {
        return (
            <VerticalSpace size={2}>
                {currentOrg?.sidebar_items?.map(item => <div key={item.name}>{collapsedComponents[item.name]}</div>)}
            </VerticalSpace>
        )
    }

    return (
        <Collapse
            activeKey={activeSidebarFilterKeys}
            onChange={keys => setActiveSidebarFilterKeys(keys)}
            bordered
            items={currentOrg?.sidebar_items?.map(item => components[item.name])}
        />
    )
}

const useFilterTitles = ()=>{
    const {t} = useTranslation();

    return {
        likes: <><ThumbsUpIcon regular/> {t('my-likes','My Likes')}</>,
        download_level: <><DownloadOutlined/> {t('download-access','Download Access')}</>,
        keywords: <><TagOutlined/> {t('keywords','Keywords')}</>,
        auto_tags: <><CloudServerOutlined/> {t('auto-tags','Auto Tags')}</>,
        moderation_names: <><EyeInvisibleOutlined/> {t('nsfw','NSFW')}</>,
        custom_meta: <><CustomMetaIcon/> {t('custom-meta','Custom Meta')}</>,
        word_proximity: <><ColumnWidthOutlined /> {t('word-proximity','Word Proximity')}</>,
        creator: <><UserOutlined/> {t('creator','Creator')}</>,
        file_ext: <><FileOutlined/> {t('file-extension','File Extension')}</>,
        aspect_ratio: <><ExpandOutlined/> {t('aspect-ration','Aspect Ratio')}</>,
        rating: <><StarOutlined/> {t('rating','Rating')}</>,
        has_people: <><UserOutlined/> {t('has-people','Has People')}</>,
        has_gps: <><PushpinOutlined/> {t('has-gps','Has GPS')}</>,
        has_alt_text: <><FileTextOutlined/> {t('has-alt-text','Has Alt Text')}</>,
        file_size: <><SaveOutlined/> {t('file-size','File Size')}</>,
        created_at: <><UploadOutlined/> {t('upload-date','Upload Date')}</>,
        updated_at: <><EditOutlined/> {t('last-update-date','Last Update Date')}</>,
        containers: <><ContainerOutlined /> {t('containers','Containers')}</>,
        rights: <><RightsIcon/> <span style={{paddingLeft:'.25em'}}>{t('rights','Rights')}</span></>,
        optimization_requests: <><ReconciliationOutlined/> {t('optimization-requests','Optimization Requests')}</>,
        unsubmitted: <><RequiredIcon color={'grey'}/> {t('unsubmitted-assets','Unsubmitted Assets')}</>,
        uploaded_by: <><UserOutlined/> <span style={{paddingLeft:'.25em'}}>{t('uploaded-by','Uploaded By')}</span></>,
        block_level: <><FlagOutlined color={'grey'}/> {t('restricted-access','Restricted Access')}</>,
        frame_status: <><FrameIcon style={{marginLeft:0}}/> {t('frame-status-filter','Frame.io Status')}</>,
        faces: <><UserOutlined/> {t('faces','Faces')}</>,
        organization: <><CollectionShareIcon/> {t('organizations','Organizations')}</>,
    }
}

export {useFilterTitles}

const Filters = ({refresh, clickRefreshFilters})=> {
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()

    const [activeSidebarMetaKeys, setActiveSidebarMetaKeys] = useStorageState(sessionStorage, `activeSidebarMetaKeys`, []);
    const [activeSidebarMetaSubKeys, setActiveSidebarMetaSubKeys] = useStorageState(sessionStorage, `activeSidebarMetaSubKeys`, {});

    const ability = useAbility(AbilityContext);

    const {filters} = useFilters();

    const currentUser = useCurrentUser();

    const [refreshUploads, setRefreshUploads] = useState(0)
    const clickRefreshUploads = (e)=> {
        e.preventDefault()
        e.stopPropagation()
        setRefreshUploads(refreshUploads + 1)
    }

    const [refreshRights, setRefreshRights] = useState(0)
    const clickRefreshRights = (e)=> {
        e.preventDefault()
        e.stopPropagation()
        setRefreshRights(refreshRights + 1)
    }

    const titles = useFilterTitles()

    const org = useCurrentOrg()
    const [filterGroups, setFilterGroups] = useState()
    const [loadingFilterGroups, setLoadingFilterGroups] = useState()

    useEffect(()=>{
      setLoadingFilterGroups(true);
      api(`/api/filter_groups`).then(res => {
          setFilterGroups(res.data)
          setLoadingFilterGroups(false)

          if(!activeSidebarMetaKeys?.length) setActiveSidebarMetaKeys([res.data[0]?.id])
      })
    }, [org?.id]);

    const filterComponents = {
        likes: <VotesFilter/>,
        download_level: <DownloadLevelFilter/>,
        keywords: <TagCloud/>,
        auto_tags: <>
            <AutoTaggedFilter/>
            <Divider/>
            <TagCloud auto/>
        </>,
        moderation_names: <ModerationNames/>,
        custom_meta: <CustomMetaFilter/>,
        word_proximity: <ProximityFilter/>,
        creator: <CreatorTagList afterConvert={refresh}/>,
        file_ext: <FileExtFilter/>,
        aspect_ratio: <AspectFilter/>,
        rating: <RatingsSlider/>,
        has_people: <HasPeopleFilter/>,
        has_gps: <HasGpsFilter/>,
        has_alt_text: <HasAltTextFilter/>,
        file_size: <FileSizeFilter/>,
        created_at: <DateFilter field={'created_at'}/>,
        updated_at: <DateFilter field={'updated_at'}/>,
        containers: <ContainersFilter/>,
        rights: <ExploreRights key={refreshRights}/>,
        optimization_requests: <OptimizationRequestFilter/>,
        unsubmitted: <UnsubmittedFilter/>,
        uploaded_by: <UploadedByFilter refreshUploads={refreshUploads}/>,
        block_level: <BlockLevelFilter/>,
        frame_status: <FrameStatusFilter/>,
        faces: <FaceFilter/>,
        organization: <OrganizationFilter/>,
    }

    const filterChecks = {
        likes: !!currentUser,
        download_level: currentOrg?.federation || ability.cannot('download_all', 'Asset'),
        auto_tags: ability.can('view_auto_tags','Asset'),
        moderation_names: ability.can('view_auto_tags','Asset') && currentOrg?.enable_nsfw_detection,
        custom_meta: currentOrg?.has_custom_meta_fields && ability.can('read', 'CustomMetaField'),
        rights: !!currentUser,
        frame_status: currentOrg?.frame_account,
        faces: currentOrg?.enable_face_tagging,
    }

    const headerExtras = {
        rights:
            <>
                <Tooltip title={t('refresh','Refresh')}>
                    <Button size='small' onClick={clickRefreshRights} type={'text'} shape={'circle'}>
                        <RefreshIcon/>
                    </Button>
                </Tooltip>

                <HelpPopover code={'rights-filters'} generalAndAdmin/>
            </>
        ,
        uploaded_by:
            <>
                <Tooltip title={t('refresh','Refresh')}>
                    <Button size='small' onClick={clickRefreshUploads} type={'text'} shape={'circle'}>
                        <RefreshIcon/>
                    </Button>
                </Tooltip>

                <HelpPopover code={'my-uploads-collapse-header'} generalAndAdmin/>
            </>,
        restricted_access: <HelpPopover code={'block-flag-filter'} generalAndAdmin/>
    }

    return (
        <Skeleton active loading={loadingFilterGroups}>
            <Collapse
                activeKey={activeSidebarMetaKeys}
                onChange={keys => setActiveSidebarMetaKeys(keys)}
                items={filterGroups?.filter(fg => fg.filters?.length)?.map(fg => {
                    return {
                        key: fg.id,
                        label: <span>{fg.name}</span>,
                        extra: headerExtras[fg.name],
                        children: (
                            <Collapse
                                activeKey={activeSidebarMetaSubKeys[fg.id]}
                                onChange={keys => {
                                    activeSidebarMetaSubKeys[fg.id] = keys
                                    setActiveSidebarMetaSubKeys({...activeSidebarMetaSubKeys});
                                }}
                                items={fg.filters.filter(f => Object.keys(filterChecks).indexOf(f.name) !== -1 ? filterChecks[f.name] : true ).map(filter => {
                                    return {
                                        key: filter.name,
                                        label: <span id={`filter-header-${filter.name}`}>{titles[filter.name]}</span>,
                                        extra: (Array.isArray(filters[filter.name]) ? !!filters[filter.name].length : filters[filter.name]) && <Tag>On</Tag>,
                                        children: (
                                            <div id={`filter-body-${filter.name}`}>
                                                {filterComponents[filter.name]}
                                            </div>
                                        )
                                    }
                                })}
                            />
                        )
                    }
                })}
            />
        </Skeleton>
    )
}

const CheckoutFilter = ({})=> {
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const cOut = aggs?.checked_out?.doc_count || 0
    const cIn = aggs?.checked_in?.doc_count || 0

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {checkout: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {checkout: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.checkout}>
                <Radio value={'out'} style={radioStyle}><LockOutlined style={{marginRight:'.5em'}}/> {t('out','Out')} <Tag>{n(cOut)}</Tag></Radio>
                <Radio value={'in'} style={radioStyle}><UnlockOutlined style={{marginRight:'.5em'}}/> {t('in','In')} <Tag>{n(cIn)}</Tag></Radio>
            </Radio.Group>

            {filters.checkout && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )
}

export {CheckoutFilter}

const RatingsSlider = ({})=>{
    const {filters, setFilters} = useFilters();

    const init = [0,5];

    const [ratingValue, setRatingValue] = useState(init);

    useEffect(()=>{
        if(!filters.rating.length && ratingValue != [0,5]) setRatingValue(init)
    }, [!filters.rating.length]);

    return (
        (<Slider
            range
            max={5}
            min={-1}
            value={ratingValue.length ? ratingValue : init}
            onChange={setRatingValue}
            onAfterChange={val => {
                setFilters(val == init, {rating: val});
            }}
            marks={{
                '-1': <RatingSliderMark value={-1}/>,
                0: <RatingSliderMark/>,
                1: <RatingSliderMark value={1}/>,
                2: <RatingSliderMark value={2}/>,
                3: <RatingSliderMark value={3}/>,
                4: <RatingSliderMark value={4}/>,
                5: <RatingSliderMark value={5}/>,
            }}
            style={{marginBottom:'3em'}}
            tooltip={{
                formatter: null
            }}
        />)
    );
}

const RatingSliderMark = ({value=0})=> {
    const {t} = useTranslation();
    const {aggs} = useAggsState();
    const total = aggs?.rating && _.find(aggs.rating.buckets, agg => agg.key == value)?.doc_count || 0;

    return (
        <>
            {value || '-'}
            <br/>
            <Tooltip title={t('total-in-results', '{{count}} in Results', {count: total})}>
                <Badge count={total} style={{backgroundColor:'#eee', color:'#999'}}/>
            </Tooltip>
        </>
    );
}

const UserFilter = ()=>{
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()
    const {selectedAssetAggs} = useSelectedAggsState();

    const ids = aggs?.user_id && _.map(aggs.user_id.buckets, 'key')

    const [usersLoading, setUsersLoading] = useState(true)
    const [users, setUsers] = useState([])

    // post to get user info
    useEffect(()=> {
        if(!ids?.length) return;

        setUsersLoading(true)
        api.post('/api/users', {ids}).then(res => {
            setUsersLoading(false)
            setUsers(res.data)
        })
    }, [ids?.length])

    return (
        <>
            {users.map(user => {
                const applied = filters.user_ids && filters.user_ids.indexOf(user.id.toString()) != -1

                const onClick = ()=>{
                    setFilters(applied, {user_ids: user.id.toString()})
                }

                const inSelection = selectedAssetAggs &&
                    _.find(selectedAssetAggs.user_id?.buckets, agg => agg.key == user.id)?.doc_count || 0

                const count = _.find(aggs.user_id.buckets, {key: user.id})?.doc_count

                return (
                    <div key={user.id} style={{marginBottom: '.5em'}}>
                        <User user={user}/>

                        <div style={{float:'right'}} key={'right'}>
                            <ClickableTag
                                style={{cursor:'pointer'}}
                                color={filters.user_ids?.indexOf(user.id.toString()) != -1 ? 'grey' : (count > 0 ? 'blue' : 'default')}
                                onClick={onClick}
                            >
                                {!!inSelection && <CheckOutlined/>} {n(count)}
                            </ClickableTag>
                        </div>
                    </div>

                )
            })}
        </>
    )
}

const HasGpsFilter = ({})=> {
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const yesCount = aggs?.has_gps?.doc_count || 0
    const noCount = aggs?.missing_gps?.doc_count || 0

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {has_gps: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {has_gps: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.has_gps}>
                <Radio value={'yes'} style={radioStyle}><CheckOutlined/> {t('yes','Yes')} <Tag>{n(yesCount)}</Tag></Radio>
                <Radio value={'no'} style={radioStyle}><CloseOutlined/> {t('no','No')} <Tag>{n(noCount)}</Tag></Radio>
            </Radio.Group>
            {filters.has_gps && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )
}

const HasAltTextFilter = ({})=> {
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const yesCount = aggs?.has_alt_text && _.find(aggs.has_alt_text.buckets, {key_as_string: 'true'})?.doc_count || 0
    const noCount = aggs?.has_alt_text && _.find(aggs.has_alt_text.buckets, {key_as_string: 'false'})?.doc_count || 0

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {has_alt_text: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {has_alt_text: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.has_alt_text}>
                <Radio value={'yes'} style={radioStyle}><CheckOutlined/> {t('yes','Yes')} <Tag>{n(yesCount)}</Tag></Radio>
                <Radio value={'no'} style={radioStyle}><CloseOutlined/> {t('no','No')} <Tag>{n(noCount)}</Tag></Radio>
            </Radio.Group>
            {filters.has_alt_text && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )
}

const AutoTaggedFilter = ()=>{
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const yesCount = aggs?.auto_tagged && _.find(aggs.auto_tagged.buckets, {key_as_string: 'true'})?.doc_count || 0
    const noCount = aggs?.auto_tagged && _.find(aggs.auto_tagged.buckets, {key_as_string: 'false'})?.doc_count || 0

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {auto_tagged: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {auto_tagged: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.auto_tagged}>
                <Radio value={'true'} style={radioStyle}><CheckOutlined/> {t('analyzed','Analyzed')} <Tag>{n(yesCount)}</Tag></Radio>
                <Radio value={'false'} style={radioStyle}><CloseOutlined/> {t('not-analyzed','Not Analyzed')} <Tag>{n(noCount)}</Tag></Radio>
            </Radio.Group>
            {filters.auto_tagged && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )

}

const HasPeopleFilter = ({})=> {
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    // const {selectedAssetAggs} = useSelectedAggsState();

    const yesCount = aggs?.has_people && _.find(aggs.has_people.buckets, {key_as_string: 'true'})?.doc_count || 0
    const noCount = aggs?.has_people && _.find(aggs.has_people.buckets, {key_as_string: 'false'})?.doc_count || 0
    const untaggedCount = aggs?.untagged_has_people?.doc_count || 0

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {has_people: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {has_people: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.has_people}>
                <Radio value={'yes'} style={radioStyle}><CheckOutlined/> {t('yes','Yes')} <Tag>{n(yesCount)}</Tag></Radio>
                <Radio value={'no'} style={radioStyle}><CloseOutlined/> {t('no','No')} <Tag>{n(noCount)}</Tag></Radio>
                <Radio value={'untagged'} style={radioStyle}><QuestionOutlined/> {t('untagged','Untagged')} <Tag>{n(untaggedCount)}</Tag></Radio>
            </Radio.Group>
            {filters.has_people && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )

}

const VotesFilter = ({})=> {
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const upCount = aggs?.up_votes?.doc_count || 0
    const downCount = aggs?.down_votes?.doc_count || 0
    const noVotes = aggs.total - (upCount + downCount)

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {vote: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {vote: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.vote}>
                <Radio value={'1'} style={radioStyle}><ThumbsUpIcon regular/> {t('liked','Liked')} <Tag>{n(upCount)}</Tag></Radio>
                <Radio value={'-1'} style={radioStyle}><ThumbsDownIcon regular/> {t('disliked','Disliked')} <Tag>{n(downCount)}</Tag></Radio>
                {/*<Radio value={'false'} style={radioStyle}><QuestionOutlined/> Untagged <Tag>{n(untaggedCount)}</Tag></Radio>*/}
            </Radio.Group>
            {filters.vote && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )

}

const UnsubmittedFilter = ({})=> {
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const yesCount = aggs?.unsubmitted?.doc_count || 0
    // const noCount = aggs?.unsubmitted && _.find(aggs.unsubmitted.buckets, {key_as_string: 'false'})?.doc_count || 0

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {unsubmitted: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {unsubmitted: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.unsubmitted}>
                <Radio value={'yes'} style={radioStyle}><RequiredIcon/> {t('unsubmitted','Unsubmitted')} <Tag>{n(yesCount)}</Tag></Radio>
                {/*<Radio value={'no'} style={radioStyle}><CheckOutlined/> Submitted <Tag>{n(noCount)}</Tag></Radio>*/}
            </Radio.Group>
            {filters.unsubmitted && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )
}

const OptimizationRequestFilter = ({})=> {
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const completedCount = aggs?.optimization_completed?.doc_count || 0
    const requestedCount = (aggs?.optimization_requested?.doc_count || 0) - completedCount

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {optimization_request: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {optimization_request: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.optimization_request}>
                <Radio value={'requested'} style={radioStyle}><AlertOutlined/> {t('requested','Requested')} <Tag>{n(requestedCount)}</Tag></Radio>
                <Radio value={'completed'} style={radioStyle}><CheckOutlined/> {t('completed','Completed')} <Tag>{n(completedCount)}</Tag></Radio>
            </Radio.Group>

            {filters.optimization_request && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )
}

const AspectFilter = ({})=> {
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const squareCount = aggs?.aspect && _.find(aggs.aspect.buckets, {key: 'square'})?.doc_count || 0
    const portraitCount = aggs?.aspect && _.find(aggs.aspect.buckets, {key: 'portrait'})?.doc_count || 0
    const landscapeCount = aggs?.aspect && _.find(aggs.aspect.buckets, {key: 'landscape'})?.doc_count || 0
    const panoramaCount = aggs?.aspect && _.find(aggs.aspect.buckets, {key: 'panorama'})?.doc_count || 0

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {aspect: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {aspect: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.aspect}>
                <Radio value={'square'} style={radioStyle}><AspectSquareIcon style={{marginRight:'.5em'}}/> {t('aspect-square','Square')} <Tag>{n(squareCount)}</Tag></Radio>
                <Radio value={'portrait'} style={radioStyle}><AspectPortraitIcon style={{marginRight:'.5em'}}/> {t('aspect-portrait','Portrait')} <Tag>{n(portraitCount)}</Tag></Radio>
                <Radio value={'landscape'} style={radioStyle}><AspectLandscapeIcon style={{marginRight:'.5em'}}/> {t('aspect-landscape','Landscape')} <Tag>{n(landscapeCount)}</Tag></Radio>
                <Radio value={'panorama'} style={radioStyle}><AspectPanoramaIcon style={{marginRight:'.5em'}}/> {t('aspect-panoroma','Panorama')} <Tag>{n(panoramaCount)}</Tag></Radio>
            </Radio.Group>
            {filters.aspect && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )

}

const FileSizeFilter = ()=>{
    const {t} = useTranslation();
    // {/* agg buckets: 0-1, 1-5, 5-25, 25-100, 100+ */}
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const aggTag = (key)=> {
        if(!aggs.file_size) return <></>

        const count = _.find(aggs.file_size.buckets, {key: key})?.doc_count
        return <Tag>{n(count)}</Tag>
    }

    const onChange = (e)=> {
        setFilters(true, {file_size_range: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {file_size_range: null})
    }

    return (
        <>
            <Space direction={'vertical'}>
                <Radio.Group value={filters.file_size_range} onChange={onChange}>
                    <Radio value={'<1'} style={radioStyle}>&lt;1 MB {aggTag('<1')}</Radio>
                    <Radio value={'1-10'} style={radioStyle}>1-10 MB {aggTag('1-10')}</Radio>
                    <Radio value={'10-50'} style={radioStyle}>10-50 MB {aggTag('10-50')}</Radio>
                    <Radio value={'50-100'} style={radioStyle}>50-100 MB {aggTag('50-100')}</Radio>
                    <Radio value={'>100'} style={radioStyle}>&gt;100 MB {aggTag('>100')}</Radio>
                </Radio.Group>

                {filters.file_size_range && (
                    <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
                )}
            </Space>
        </>
    )
}

const DateRangePickerFilter = ({field, onChange}) => {
    const {filters} = useFilters();
    const {aggs} = useAggsState()

    const value = filters[field]?.length ? filters[field] : null

    const [range, setRange] = useState()
    useEffect(()=> {
        setRange(value && [moment.utc(value[0]), moment.utc(value[1])])
    }, [value && value[0], value && value[1]])

    const getLabel = (key)=> {
        const count = _.find(aggs[field]?.buckets, {key: key})?.doc_count || 0

        return `${key} (${n(count)})`
    }

    const change = (newRange)=> {
        setRange(newRange)
        onChange(newRange)
    }

    return (
        <DateRangePicker onChange={change} value={range} showTime getLabel={getLabel}/>
    )
}

const DateFilter = ({field, dragDrop, refresh})=> {
    const {setFilters, removeFilter} = useFilters();

    const onChange = (range)=> {
        const attrs = {}
        attrs[field] = range ? [range[0].format(), range[1].format()] : []
        setFilters(false, attrs)
    }

    const currNode = useRef()

    const onSelect = node=>{
        const start = moment.utc(node.value).startOf(node.interval)
        const finish = moment.utc(node.value).endOf(node.interval)
        const attrs = {}
        attrs[field] = [start.format(), finish.format()]

        if(currNode.current?.key === node.key) {
            removeFilter(field)
            currNode.current = null
        } else {
            if(filters.missing_captured_at) removeFilter('missing_captured_at')

            setFilters(false, attrs)
            currNode.current = node
        }
    }

    return (
        <VerticalSpace>
            <DateRangePickerFilter field={field} onChange={onChange}/>
            <DateTree field={field} onSelect={onSelect} dragDrop={dragDrop} refresh={refresh}/>
        </VerticalSpace>
    )
}

const DateTreeContext = createContext(null);

const DateTree = ({field, onSelect, dragDrop, refresh}) => {
    const {aggs} = useAggsState()
    const {filters} = useFilters();

    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState()

    const [treeData, setTreeData ] = useState([])
    const [loadedKeys, setLoadedKeys] = useState([])
    const [selectedKeys, setSelectedKeys] = useState([])

    const [dateAggs, setDateAggs] = useState({})

    const getTreeDataNode = node => {
        const find = (checkNode)=> {
            if(checkNode.key === node.key) return checkNode;
            if(!checkNode.children) return;

            let found;
            for(let n of checkNode.children) {
                found = find(n)
                if(found) break
            }
            return found;
        }

        return find({children: treeData})
    }

    let newTreeData, newLoadedKeys;
    const loadData = (node, aggsOnly=false)=> {
        if(!node.childInterval) return;

        if(!newTreeData) newTreeData = treeData;
        if(!newLoadedKeys) newLoadedKeys = loadedKeys;

        return new Promise(resolve => {
            const done = ()=> {
                setLoadedKeys([...newLoadedKeys, node.key])
                setTreeData([...newTreeData])

                setLoading(false)
                setLoaded(true)
                resolve()
            }

            const data = {
                date_aggregates_only: true,
                include_totals: true,
                aggregates_only: true,
                date_tree_field: field,
                date_tree_interval: node.childInterval,
            }

            if(aggsOnly) {
                _.merge(data, window.currentSearchParams)
            }

            if(node.value || node.value === 0) {
                data.date_tree_value = [moment.utc(node.value).startOf(node.interval), moment.utc(node.value).endOf(node.interval)]
            }

            const treeNode = getTreeDataNode(node)

            api.post('/api/assets/search', data).then(res => {
                if(!res.data?.aggs) return;

                const aggName = `${field}_${node.childInterval}`
                const buckets =
                    res.data.aggs[aggName]?.buckets ||
                        res.data.aggs[aggName][`${aggName}_truncated`]?.buckets

                if(!buckets) return;

                if(aggsOnly) {
                    setDateAggs(dateAggs => {
                        buckets.map(b => {
                            const year = new Date(b.key).getUTCFullYear()
                            const month = new Date(b.key).getUTCMonth()
                            const day = new Date(b.key).getUTCDate()

                            let key;
                            if(node.childInterval === 'year') key = `${year}`
                            else if(node.childInterval === 'month') key = `${year}-${month}`
                            else key = `${year}-${month}-${day}`;

                            dateAggs[key] = b.doc_count;
                        })

                        return {...dateAggs}
                    })

                } else {
                    const nodes =
                        buckets.filter(b => b.doc_count > 0).map(b => {
                            const year = new Date(b.key).getUTCFullYear()
                            const month = new Date(b.key).getUTCMonth()
                            const day = new Date(b.key).getUTCDate()

                            let key;
                            if(node.childInterval === 'year') {
                                const key = `${year}`;
                                return {
                                    key,
                                    title: <DateTreeNodeTitle date={moment.utc(b.key).format('YYYY')} count={b.doc_count} dateKey={key} value={b.key} interval={'year'}/>,
                                    value: b.key,
                                    count: b.doc_count,
                                    interval: 'year',
                                    childInterval: 'month',
                                    isLeaf: false
                                }
                            } else if(node.childInterval === 'month') {
                                const key = `${year}-${month}`;
                                return {
                                    key,
                                    title: <DateTreeNodeTitle date={moment.utc(b.key).format('MMMM')} count={b.doc_count} dateKey={key} value={b.key} interval={'month'}/>,
                                    value: b.key,
                                    count: b.doc_count,
                                    interval: 'month',
                                    childInterval: 'day',
                                    isLeaf: false
                                }
                            } else {
                                const key = `${year}-${month}-${day}`;
                                return {
                                    key,
                                    title: <DateTreeNodeTitle date={moment.utc(b.key).format('Do - ddd')} count={b.doc_count} dateKey={key} value={b.key} interval={'day'}/>,
                                    value: b.key,
                                    count: b.doc_count,
                                    interval: 'day',
                                    isLeaf: true
                                }
                            }
                        })

                    // Check nodes to expand based on filter:
                    nodes.map(n => toExpand.indexOf(n.key) !== -1 && setExpandedKeys(expandedKeys => [...expandedKeys, n.key]))

                    if(treeNode.key) {
                        treeNode.children = nodes;
                    } else {
                        newTreeData.push(...nodes)
                    }
                }

                done()
            })
        })
    }

    const previousRefresh = useRef(0)

    useEffect(()=>{
        if(treeData.length && previousRefresh.current === refresh) return;

        setLoading(true)
        newTreeData = []
        newLoadedKeys = []
        loadData({childInterval: 'year'})

        previousRefresh.current = refresh
    },[refresh]);

    const onSelectWrapper = (keys,{node}) => {
        setSelectedKeys(keys)
        onSelect(node)
    }

    const [toExpand, setToExpand ] = useState([])

    useEffect(()=>{
        if(!loaded) return;

        if(filters[field] && !filters[field].length) {
            // Clear selection when filter removed:
            setSelectedKeys(null)
        } else {
            // Determine selected key from values:
            // - get seconds difference, check if day, month, year size

            const value = filters[field]
            const start = moment.utc(value[0])
            const finish = moment.utc(value[1])

            let key, toExpand = [];
            if(start.date() === finish.date() && start.month() === finish.month() && start.year() === finish.year()) {
                key = `${start.year()}-${start.month()}-${start.date()}`;
                toExpand = [`${start.year()}`, `${start.year()}-${start.month()}`]

            } else if(start.month() === finish.month() && start.year() === finish.year()) {
                key = `${start.year()}-${start.month()}`;
                toExpand = [`${start.year()}`]

            } else if(start.year() === finish.year()) {
                key = `${start.year()}`;
            }

            if(key && (!selectedKeys || selectedKeys[0] != key)) {
                setSelectedKeys([key])
                setToExpand(toExpand)
                setExpandedKeys(expandedKeys => [...expandedKeys, ...toExpand])
            }
        }

    },[loaded, filters[field]])

    useEffect(()=>{
        setDateAggs({})

        // Iterate over open nodes and get aggs:
        loadData({childInterval: 'year'}, true)

        expandedKeys.map(key => {
            console.log('getting expanded key:', key)
            const node = getTreeDataNode({key});
            if(node) loadData(node, true)
        })
    }, [aggs])

    useEffect(()=>{
        if(refresh === 0) return

        setLoading(true)
        setTreeData([])
    }, [refresh])

    const [expandedKeys, setExpandedKeys] = useState([])

    const onExpand = (keys, {expanded, node})=> {
        setExpandedKeys(expandedKeys => {
            return _.uniq(expanded ? expandedKeys.concat(node.key) : _.without(expandedKeys, node.key))
        });

        if(expanded) loadData(node, true)
    }

    return (
        <div id={`date-tree-${field}`}>
            <Skeleton loading={loading}>
                <DateTreeContext.Provider value={{dateAggs, onSelect, field, dragDrop}}>
                    {!!treeData?.length && (
                        <Tree
                            blockNode
                            selectable
                            multiple
                            treeData={treeData}
                            loadData={loadData}
                            loadedKeys={loadedKeys}
                            onSelect={onSelectWrapper}
                            selectedKeys={selectedKeys}
                            onExpand={onExpand}
                            expandedKeys={expandedKeys}
                        />
                    )}
                </DateTreeContext.Provider>
            </Skeleton>
        </div>
    )
}

const DateTreeNodeTitle = ({date, count, dateKey, value, interval})=>{
    const {t} = useTranslation();
    const {dateAggs, onSelect, field, dragDrop} = useContext(DateTreeContext)
    const inResults = dateAggs[dateKey];

    const {selectedAssetAggs} = useSelectedAggsState();

    const aggName = `${field}_${interval}`
    const buckets =
        selectedAssetAggs[aggName] && (
            selectedAssetAggs[aggName].buckets ||
            selectedAssetAggs[aggName][`${aggName}_truncated`]?.buckets
        )

    const [displayCount, setDisplayCount] = useState(count)
    // console.log(date, count, displayCount)

    const selectedCount = _.find(buckets, b => b.key === value)?.doc_count || 0;

    const {clearFilters} = useFilters()

    const navigate = useNavigate();
    const getPath = useOrgPath()

    const viewAll = e =>{
        e.preventDefault()
        e.stopPropagation()

        clearFilters()
        navigate(getPath(`/explore`))
        onSelect({value, interval, key: dateKey});
    }

    //--------------------
    // Asset Drop Handling
    //--------------------
    const bulkJobDispatch = useBulkJobsDispatch()
    const [bulkJobId, setBulkJobId] = useState()

    const {selectedAssetIds} = useSelectedAssetsState();

    const [{canDrop, isOver}, drop] = !dragDrop ? [{}] : useDrop({
        accept: 'asset',
        drop: ({asset}) => {
            const asset_ids = [...new Set([asset.id, ...selectedAssetIds])];

            const data = {
                bulk_job: {
                    asset_ids,
                }
            }
            data.bulk_job[field] = moment.utc(value).format()
            data.bulk_job[`${field}_truncation`] = interval

            // Create BulkJob and listen:
            message.info({content: t('message-starting-bulk-job','Starting Bulk Job...'), key:'bulkJob'})

            api.post(`/api/bulk_jobs`, data).then(res => {
                setBulkJobId(res.data.guid)
                bulkJobDispatch({type:'add', bulkJob: res.data})
            }).catch((err)=>{
                console.log(err)
            })
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        hover: (item) => {
            // console.log('hovering', props.title);
        }
    });

    const isActive = canDrop && isOver;

    const style = isActive ? {
        border: '1px solid #b7eb8f',
        backgroundColor: '#f6ffed'
    } : {};

    const onBulkAddFinish = bj =>{
        console.log('onBulkAddFinish', bj)
        message.destroy('bulkJob')
        message.success(`${bj.added} assets updated.`)
        setDisplayCount(displayCount => displayCount + bj.added)
    }
    //--------------------

    return (
        <div style={{display:'flex', width:'100%', justifyContent:'flex-start', alignItems:'center', ...style}} ref={drop}>
            <div className={'node-name'}>{date}</div>

            <div style={{marginLeft:'auto'}}>
                <Popover
                    placement={'right'}
                    content={
                        <VerticalSpace>
                            <Button onClick={viewAll} icon={<EyeOutlined/>}>View All With Date&nbsp;<Tag>{displayCount}</Tag></Button>
                        </VerticalSpace>
                    }
                >
                    <Tag color={inResults > 0 ? 'blue' : null}>
                        {selectedCount > 0 && <CheckOutlined className={'in-results'}/>} {displayCount}
                    </Tag>
                </Popover>
            </div>

            {bulkJobId && <AssetGroupBulkJobProgress bulkJobId={bulkJobId} onFinish={onBulkAddFinish}/>}
        </div>
    )
}

const MissingCapturedAtFilter = ()=>{
    const {t} = useTranslation();

    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const applied = !!filters.missing_captured_at

    const onChange = () => {
        setFilters(applied, {missing_captured_at: !applied})
    }

    return (
        <Checkbox
            checked={applied}
            onChange={onChange}
            disabled={filters.captured_at?.length}
        >
            {t('missing-create-date', 'Missing Create Date')}

            {!filters.captured_at?.length && (
                <Tag style={{marginLeft:'.5em'}}>
                    {aggs?.missing_captured_at?.doc_count}
                </Tag>
            )}
        </Checkbox>
    )
}

const TagCloud = ({auto, limit, showVisibleAssetCount})=> {
    const {currentTag} = useAssetGroupState()
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const aggValue = auto ? aggs?.auto_tag_names : aggs?.visible_tag_names;

    const tag_names = aggValue && aggValue.buckets.map(agg => agg.key)

    const [loading, setLoading] = useState(true)
    const [tags, setTags] = useState([])

    const ability = useAbility(AbilityContext)
    const editable = ability.can('edit', 'Tag') && !auto

    const [filteredTypes, setFilteredTypes] = useState(['all'])

    const onFilterType = type => {
        return ()=>{
            if(type === 'all') {
                setFilteredTypes(['all'])

            } else {
                if(filteredTypes.includes('all'))
                    filteredTypes.splice(filteredTypes.indexOf('all'), 1)

                if(filteredTypes.includes(type)) {
                    filteredTypes.splice(filteredTypes.indexOf(type), 1)
                } else {
                    filteredTypes.push(type)
                }

                setFilteredTypes(filteredTypes.length ? [...filteredTypes] : ['all'])
            }
        }
    }

    const loadTags = ()=>{
        setLoading(true)

        const data = {...window.currentSearchParams, tag_aggregates_only: true, include_totals: true, aggregates_only: true, tag_sub_types: filteredTypes}

        api.post('/api/assets/search', data).then(res => {
            setTags(Array.isArray(res.data) ? res.data : [])
            setLoading(false)
        })
    }

    useEffect(()=>{
        if(auto) {
            setTags([])
            if(!tag_names?.length) setLoading(false)

            setLoading(true)
            api.post(`/api/auto_tags/bulk_find`, {tag_names}).then(res => {
                setTags(res.data.sort((a,b) => b.visible_assets_count - a.visible_assets_count))
                setLoading(false)
            })

        } else {
            loadTags()
        }
    }, [filteredTypes, tag_names?.join('')])
    
    const {t} = useTranslation();

    return (
        <>
            {!auto && (
                <div style={{marginBottom:'1em'}}>
                    {[
                        {label: <Tooltip title={t('show-all-tags','Show All Tags')}>{t('all','All')}</Tooltip>, value:'all'},
                        {label: <Tooltip title={t('filter-by-product-tags','Filter By Product Tags')}><ShoppingCartOutlined/></Tooltip>, value: 'product'},
                        {label: <Tooltip title={t('filter-by-person-tags','Filter By Person Tags')}><UserOutlined/></Tooltip>, value:'person'},
                        {label: <Tooltip title={t('filter-by-keyword-tags','Filter By Keyword Tags')}><TagOutlined/></Tooltip>, value:'keyword'},
                        {label: <Tooltip title={t('filter-by-event-tags','Filter By Event Tags')}><CalendarOutlined/></Tooltip>, value:'event'},
                    ].map(filter => {
                        const selected = filteredTypes[0] === 'all' || filteredTypes.includes(filter.value)

                        return (
                            <Button
                                // type={''}
                                style={{color: selected ? Colors.blue : 'black', marginRight: 5}}
                                icon={filter.label}
                                onClick={onFilterType(filter.value)}
                                key={filter.value}
                            />
                        )
                    })}
                </div>
            )}

            {loading ? <LoadingOutlined/> : (
                <Flex wrap={'wrap'} gap={'small'}>
                    {!!tags?.length ? tags.map((tag,i) => {
                        if(limit && i > limit) return <></>;

                        const tagFilters = auto ? filters.auto_tags : filters.tags

                        const applied = tagFilters && tagFilters.indexOf(tag.name) != -1
                        const hidden = filters.hide_tags && filters.hide_tags.indexOf(tag.name) != -1

                        const onClick = ()=>{
                            setFilters(applied, auto ? {auto_tags: tag.name} : {tags: tag.name})
                        }

                        if(!tag || currentTag?.name === tag.name) return <></>

                        const count = (auto ? aggValue.buckets.find(b => b.key === tag.name?.toLowerCase())?.doc_count : tag.visible_assets_count) || 0

                        return <EditableTag key={tag.id} filter badgeCount={count} {...{editable, tag, applied, onClick, hidden, auto}}/>
                    }) : <em>{t('none','None')}...</em>}

                    {aggValue && !aggValue.buckets.length && (
                        <em>{t('no','No')} {auto ? t('auto-tags','Auto Tags') : t('keywords','Keywords')}...</em>
                    )}
                </Flex>
            )}

        </>
    )
}

export {TagCloud}

const CustomMetaFilter = ()=>{
    const {t} = useTranslation();
    const org = useCurrentOrg()

    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const [loading, setLoading] = useState(true)
    const [customMetaFields, setCustomMetaFields] = useState([])

    useEffect(()=> {
        if(!org) return

        api('/api/custom_meta_fields').then(res => {
            setLoading(false)
            setCustomMetaFields(res.data)
        })
    }, [org?.id])

    const onChange =  cmf =>{
        return value => {
            if(!filters.custom_meta) filters.custom_meta = {}
            filters.custom_meta[cmf.name] = value

            setFilters(!!(filters.custom_meta && filters.custom_meta[cmf.name]), {custom_meta: filters.custom_meta})
        }
    }

    return (
        <>
            <Skeleton loading={loading} active>
                <Descriptions bordered size='small' column={1}>
                    {customMetaFields.filter(cmf => !cmf.free).map(cmf => {
                        const props = {}
                        const value = filters.custom_meta && filters.custom_meta[cmf.name]

                        if(value) props.value = value

                        return (
                            <Descriptions.Item
                                label={<>{cmf.name}<br/>{cmf.description && <small><em>{cmf.description}</em></small>}</>}
                                key={cmf.name}
                                style={{width: '33%'}}
                            >
                                <AntSelect
                                    name={cmf.name}
                                    mode={cmf.multi && 'multiple'}
                                    placeholder={`Select ${cmf.multi ? 'Multiple' : 'One'}...`}
                                    style={{width: '100%'}}
                                    allowClear
                                    onChange={onChange(cmf)}
                                    getPopupContainer={e => document.getElementById('explore-filters-panel')}
                                    {...props}
                                >
                                    {cmf.custom_meta_values_attributes.map(cmv => {
                                        const count = aggs[`cmf${cmf.number}.keyword`]?.buckets?.find(b => b.key == cmv.text)?.doc_count || 0

                                        return (
                                            <AntSelect.Option key={cmv.id} value={cmv.text}>
                                                <Flex justify={'space-between'} align={'center'} gap={6}>
                                                    {cmv.color_label && <Badge color={cmv.color_label}/>}
                                                    <>{cmv.text}</>
                                                    <Tag>{n(count)}</Tag>
                                                </Flex>
                                            </AntSelect.Option>
                                        );
                                    })}
                                    <AntSelect.Option key={'blank'} value={'_blank'}>
                                        <Flex justify={'space-between'} align={'center'} gap={6}>
                                            <em><Typography.Text type={'secondary'}>{t('blank','Blank')}</Typography.Text></em>
                                            <Tag>{n(aggs[`cmf${cmf.number}_blank`]?.doc_count || 0)}</Tag>
                                        </Flex>
                                    </AntSelect.Option>
                                </AntSelect>

                            </Descriptions.Item>
                        );
                    })}
                </Descriptions>
            </Skeleton>

        </>
    )

}

const ModerationNames = ()=>{
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const yesCount = aggs?.nsfw_detected && _.find(aggs.nsfw_detected.buckets, {key_as_string: 'true'})?.doc_count || 0
    const noCount = aggs?.nsfw_detected && _.find(aggs.nsfw_detected.buckets, {key_as_string: 'false'})?.doc_count || 0
    const clearedCount = aggs?.nsfw_detected && _.find(aggs.nsfw_cleared.buckets, {key_as_string: 'true'})?.doc_count || 0

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        const value = e.target.value
        setFilters(true, value == 'cleared' ? {nsfw_cleared: 'true', nsfw_detected: null} : {nsfw_detected: value, nsfw_cleared: null})
    }

    const reset = ()=> {
        setFilters(true, {nsfw_detected: null, nsfw_cleared: null})
    }

    const radioValue = filters.nsfw_detected || (filters.nsfw_cleared && 'cleared')

    return (
        <>
            <VerticalSpace style={{marginBottom:'1em'}}>
                <Radio.Group onChange={change} value={radioValue}>
                    <Radio value={'true'} style={radioStyle}><EyeInvisibleOutlined/> {t('any-nsfw','Any NSFW')} <Tag>{n(yesCount)}</Tag></Radio>
                    <Radio value={'false'} style={radioStyle}><CloseOutlined/> {t('none-detected','None Detected')} <Tag>{n(noCount)}</Tag></Radio>
                    <Radio value={'cleared'} style={radioStyle}><CheckOutlined/> {t('cleared','Cleared')} <Tag>{n(clearedCount)}</Tag></Radio>
                </Radio.Group>

                {(filters.nsfw_detected || filters.nsfw_cleared) && (
                    <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
                )}
            </VerticalSpace>

            <Divider/>

            {aggs.moderation_names?.buckets?.filter(agg => agg.key != 'false')?.map(agg => {

                const applied = filters.moderation_names && filters.moderation_names.indexOf(agg.key) != -1

                const addFilter = ()=>{
                    setFilters(applied, {moderation_names: agg.key})
                }
                return (
                    <Tag
                        tabIndex={0}
                        key={agg.key}
                        onClick={addFilter}
                        color={applied && 'blue'}
                        style={{cursor:'pointer', margin:'0 .5em .5em 0'}}
                    >
                        {agg.key} <GreyBadge count={agg.doc_count}/>
                    </Tag>
                )
            })}
        </>
    )
}

const ProximityFilter = ()=> {
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();

    return (
        <Formik
            enableReinitialize
            initialValues={{
                field: filters.proximity_field || 'transcript',
                word_1: filters.proximity_word_1,
                word_2: filters.proximity_word_2,
                max_gaps: filters.proximity_max_gaps
            }}
            onSubmit={(values)=>{
                setFilters(false, {
                    proximity_field: values.field,
                    proximity_word_1: values.word_1,
                    proximity_word_2: values.word_2,
                    proximity_max_gaps: values.max_gaps,
                })
            }}
        >
            {({submitForm, handleSubmit, dirty, resetForm})=>{
                const reset = ()=>{
                    resetForm()
                    setFilters(true, {
                        proximity_field: 'transcript',
                        proximity_word_1: null,
                        proximity_word_2: null,
                        proximity_max_gaps: null,
                    })
                }
                return (
                    <Form onSubmit={handleSubmit}>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Tooltip title={'Select the field to search on.'}>
                                <Select name={'field'} style={{width:'100%'}}>
                                    <Select.Option value={'transcript'}>{t('transcript','Transcript')}</Select.Option>
                                    <Select.Option value={'description'}>{t('description','Description')}</Select.Option>
                                    <Select.Option value={'content'}>{t('content','Content')}</Select.Option>
                                </Select>
                            </Tooltip>

                            <Row gutter={8}>
                                <Col span={12}>
                                    <Input placeholder={t('word-1','Word 1')} name={'word_1'} style={{width:'100%'}} required autoComplete={'off'}/>
                                </Col>

                                <Col span={12}>
                                    <Input placeholder={t('word-2','Word 2')} name={'word_2'} style={{width:'100%'}} required autoComplete={'off'}/>
                                </Col>
                            </Row>

                            <Input type='number' placeholder={t('max-distance-between-words','Max Distance Between Words')} name={'max_gaps'} required/>

                            <Row gutter={8}>
                                <Col span={24}>
                                    <Button block icon={<CheckOutlined/>} onClick={submitForm} disabled={!dirty}>{t('apply','Apply')}</Button>
                                </Col>
                                {/*<Col span={4}>*/}
                                {/*    <Tooltip title={'Clear'}>*/}
                                {/*        <Button block icon={<CloseCircleOutlined/>} onClick={reset} type={'text'}></Button>*/}
                                {/*    </Tooltip>*/}
                                {/*</Col>*/}
                            </Row>
                        </Space>
                    </Form>
                )
            }}
        </Formik>
    )
}

const Events = ({refreshEvents, setRefreshEvents})=>{
    const {t} = useTranslation();
    const [modalVisible, setModalVisible] = useState()

    return (
        (<Space direction={'vertical'} width={'100%'}>
            <Button onClick={()=> setModalVisible(true)} block icon={<ArrowsAltOutlined />}>{t('view-full-calendar','View Full Calendar')}</Button>
            <FullCalendar mini refresh={refreshEvents}/>
            <Modal
                title={<><CalendarOutlined/> {t('events','Events')}</>}
                width={isMobile() ? '100%' : '75%'}
                open={modalVisible}
                onCancel={()=> setModalVisible(false)}
                footer={null}
            >
                <FullCalendar onUpdate={()=> setRefreshEvents(refreshEvents + 1)}/>
            </Modal>
        </Space>)
    );
}

const BlockLevelFilter = ({})=> {
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const noneCount =
        (aggs?.block_level && _.find(aggs.block_level.buckets, {key: 0})?.doc_count || 0) +
        (aggs?.missing_block_level?.doc_count || 0)

    const downloadCount = aggs?.block_level && _.find(aggs.block_level.buckets, {key: 1})?.doc_count || 0
    const viewCount = aggs?.block_level && _.find(aggs.block_level.buckets, {key: 2})?.doc_count || 0

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {block_level: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {block_level: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.block_level}>
                <Radio value={'0'} style={radioStyle}>{t('none','None')} <Tag>{n(noneCount)}</Tag></Radio>
                <Radio value={'1'} style={radioStyle}><BlockDownload/> {t('download-blocked','Download Blocked')} <Tag>{n(downloadCount)}</Tag></Radio>
                <Radio value={'2'} style={radioStyle}><BlockView/> {t('view-download-blocked','View / Download Blocked')} <Tag>{n(viewCount)}</Tag></Radio>
            </Radio.Group>
            {filters.block_level && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )
}

const FrameStatusFilter = ({})=> {
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const needsReviewCount = aggs?.frame_status && _.find(aggs.frame_status.buckets, {key: 'needs_review'})?.doc_count || 0
    const inProgressCount = aggs?.frame_status && _.find(aggs.frame_status.buckets, {key: 'in_progress'})?.doc_count || 0
    const approvedCount = aggs?.frame_status && _.find(aggs.frame_status.buckets, {key: 'approved'})?.doc_count || 0
    const noneCount =
        (aggs?.frame_status && _.find(aggs.frame_status.buckets, {key: 'none'})?.doc_count || 0) +
            (aggs?.frame_status_none?.doc_count || 0)

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {frame_status: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {frame_status: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.frame_status}>
                <Radio value={'needs_review'} style={radioStyle}><FrameStatusIcon status={'needs_review'}/> {t('frame-status-needs_review','Needs Review')} <Tag>{n(needsReviewCount)}</Tag></Radio>
                <Radio value={'in_progress'} style={radioStyle}><FrameStatusIcon status={'in_progress'}/> {t('frame-status-in_progress','In Progress')} <Tag>{n(inProgressCount)}</Tag></Radio>
                <Radio value={'approved'} style={radioStyle}><FrameStatusIcon status={'approved'}/> {t('frame-status-approved','Approved')} <Tag>{n(approvedCount)}</Tag></Radio>
                <Radio value={'none'} style={radioStyle}><FrameStatusIcon status={'none'}/> {t('frame-status-none','None')} <Tag>{n(noneCount)}</Tag></Radio>
            </Radio.Group>
            {filters.frame_status && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )

}

const FaceFilter = ({limit})=> {
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const aggValue = aggs?.face_names;

    const tag_names = aggValue && aggValue.buckets.map(agg => agg.key)

    const [loading, setLoading] = useState(true)
    const [tags, setTags] = useState([])

    const ability = useAbility(AbilityContext)
    const editable = ability.can('edit', 'Tag')

    const org = useCurrentOrg()

    const loadTags = ()=>{
        setLoading(true)

        const data = {...window.currentSearchParams, tag_aggregates_only: true, include_totals: true, aggregates_only: true, tag_sub_types: ['person'], face: true}

        api.post('/api/assets/search', data).then(res => {
            setTags(Array.isArray(res.data) ? res.data : [])
            setLoading(false)
        })
    }

    useEffect(()=>{
        loadTags()
    }, [tag_names?.join('')])

    const {t} = useTranslation();

    if(!org?.enable_face_tagging) return <></>

    return (
        <>
            {loading ? <LoadingOutlined/> : (
                <>
                    {!!tags?.length ? tags.map((tag,i) => {
                        if(limit && i > limit) return <></>;

                        const tagFilters = filters.face_tags

                        const applied = tagFilters && tagFilters.indexOf(tag.name) != -1

                        const onClick = ()=>{
                            setFilters(applied, {face_tags: tag.name})
                        }

                        if(!tag || tag.list === 'blocked') return <></>

                        return <EditableTag key={tag.id} face filter badgeCount={tag.visible_assets_count || 0} {...{editable, tag, applied, onClick}}/>
                    }) : <em>{t('none','None')}...</em>}
                </>
            )}

            <Divider size={'small'}/>

            <VerticalSpace>
                <UnidentifiedFacesFilter/>
                <FacesNotSearchedFilter/>
            </VerticalSpace>

            {/*<Divider size={'small'}/>*/}

            {/*<FacesIndexedFilter/>*/}
        </>
    )
}

const FacesIndexedFilter = ()=>{
    const {t} = useTranslation();
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const yesCount = aggs?.faces_indexed && _.find(aggs.faces_indexed.buckets, {key_as_string: 'true'})?.doc_count || 0
    const noCount = aggs?.faces_indexed && _.find(aggs.faces_indexed.buckets, {key_as_string: 'false'})?.doc_count || 0

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const change = (e) => {
        setFilters(true, {faces_indexed: e.target.value})
    }

    const reset = ()=> {
        setFilters(true, {faces_indexed: null})
    }

    return (
        <Space direction={'vertical'}>
            <Radio.Group onChange={change} value={filters.faces_indexed}>
                <Radio value={'true'} style={radioStyle}><CheckOutlined/> {t('analyzed','Analyzed')} <Tag>{n(yesCount)}</Tag></Radio>
                <Radio value={'false'} style={radioStyle}><CloseOutlined/> {t('not-analyzed','Not Analyzed')} <Tag>{n(noCount)}</Tag></Radio>
            </Radio.Group>
            {filters.faces_indexed && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </Space>
    )
}

const UnidentifiedFacesFilter = ()=>{
    const {t} = useTranslation();

    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const applied = !!filters.unidentified_faces

    const yesCount = aggs?.unidentified_faces && _.find(aggs.unidentified_faces.buckets, {key_as_string: 'true'})?.doc_count || 0
    const noCount = aggs?.unidentified_faces && _.find(aggs.unidentified_faces.buckets, {key_as_string: 'false'})?.doc_count || 0

    const onChange = () => {
        setFilters(applied, {unidentified_faces: !applied})
    }

    return (
        <Checkbox
            checked={applied}
            onChange={onChange}
            disabled={filters.face_tags?.length}
        >
            {t('has-unidentified-faces', 'Has Unidentified Faces')}

            {!filters.unidentified_faces?.length && (
                <Tag style={{marginLeft:'.5em'}}>
                    {yesCount}
                </Tag>
            )}
        </Checkbox>
    )
}

const FacesNotSearchedFilter = ()=>{
    const {t} = useTranslation();

    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    const applied = !!filters.faces_not_searched

    const count = aggs?.faces_not_searched?.doc_count || 0

    const onChange = () => {
        setFilters(applied, {faces_not_searched: !applied})
    }

    return (
        <Checkbox
            checked={applied}
            onChange={onChange}
            disabled={filters.face_tags?.length}
        >
            {t('faces-not-searched', 'Faces Not Searched')}

            {!filters.faces_not_searched?.length && (
                <Tag style={{marginLeft:'.5em'}}>
                    {count}
                </Tag>
            )}
        </Checkbox>
    )
}

const OrganizationFilter = ()=>{
    const {filters, setFilters, removeFilter} = useFilters();
    const {aggs} = useAggsState()

    const org = useCurrentOrg();

    console.log('filters', filters.organization_id)

    const onChange = (e)=>{
        const id = e.target.value
        const applied = e.target.checked

        if(id === 'all') {
            removeFilter('organization_id')
        } else {
            setFilters(!applied, {organization_id: id})
        }
    }
    
    return (
        <VerticalSpace>
            {[{id: 'all', title: 'All'}, ...org.federation.organizations.filter(o => o.id != org.id)].map(o => {
                const applied = (o.id === 'all' && !filters.organization_id.length) || filters.organization_id?.includes(o.id.toString())

                const count = aggs?.organization_id?.buckets.find(b => b.key === o.id)?.doc_count || 0

                return (
                    <Checkbox
                        checked={applied}
                        onChange={onChange}
                        value={o.id}
                    >
                        <Space size={'small'}>
                            {o.title} {o.id !== 'all' && <Tag>{count}</Tag>}
                        </Space>
                    </Checkbox>
                );
            })}


        </VerticalSpace>
    )
}

const AssetGroupFilter = ({type})=> {
    const {filters, setFilters, removeFilter} = useFilters();

    let filter;
    switch(type) {
        case 'storage_folders':
            filter = 'storage_folder_ids'
            break;
        case 'collections':
            filter = 'collection_ids'
            break;
        case 'lightboxes':
            filter = 'lightbox_ids'
            break;
    }

    let klass;
    switch(type) {
        case 'storage_folders':
            klass = 'StorageFolder'
            break;
        case 'collections':
            klass = 'Collection'
            break;
        case 'lightboxes':
            klass = 'Lightbox'
            break;
    }

    const setSelectedGroup = assetGroup => {
        const {id} = assetGroup;

        const attrs = {}
        attrs[filter] = id

        const applied = filters[filter] && filters[filter].indexOf(id) !== -1

        if(applied) removeFilter(filter)
        else setFilters(applied, attrs)
    }

    return (
        (<AssetGroupChooser
            showAggs
            showAggsCount
            organizerSelection
            type={type}
            readOnly
            hideAddButton
            hideViewAll
            setSelectedGroup={setSelectedGroup}
            current={{id: parseInt(filters[filter]?.match(/\d+/)), type: klass}}
            draggable={false}
            sessionKey={`filter-${type}`}
        />)
    );
}

const DownloadLevelFilter = ()=>{
    const {t} = useTranslation();
    const {filters, setFilters, removeFilter} = useFilters();

    const changeWatermark = watermark => {
        setFilters(true, {watermark})
    }

    const reset = ()=> {
        removeFilter(['download_levels', 'watermark'])
    }

    const {assetsLoading} = useLoadedAssetsState();
    const [aggs, setAggs] = useState()

    useEffect(()=>{
        if(assetsLoading) return

        setAggs(false)
        const data = {...window.currentSearchParams, include_download_aggregates: true, download_aggregates_only: true, include_totals: true}

        api.post('/api/assets/search', data).then(res => {
            setAggs(res.data.aggs)
            console.log(res.data)
        })

    }, [assetsLoading])

    const Agg = ({level, onClick}) => {
        const n = aggs && aggs[`download_level_${level}`]?.doc_count
        return (
            <Tag style={{marginLeft:'auto', cursor:'pointer'}} onClick={onClick}>
                {aggs ? n : <LoadingOutlined/>}
            </Tag>
        )
    }

    const LevelCheckBox = ({level, children})=>{
        const applied = filters.download_levels?.includes(level)

        const toggleLevel = () => {
            setFilters(applied, {download_levels: level})
        }

        return (
            <div style={{display:'flex', width:'100%'}}>
                <Checkbox
                    checked={applied}
                    onChange={toggleLevel}
                >
                    {children}
                </Checkbox>

                <Agg level={level} onClick={toggleLevel}/>
            </div>
        )
    }

    return (
        <VerticalSpace>
            <LevelCheckBox level={'original'}>{t('original-file','Original File')}</LevelCheckBox>
            <LevelCheckBox level={'full'}>{t('full-resolution','Full Resolution')}</LevelCheckBox>
            <LevelCheckBox level={'medium'}>{t('medium-resolution','Medium Resolution')}</LevelCheckBox>
            <LevelCheckBox level={'small'}>{t('small-resolution','Small Resolution')}</LevelCheckBox>
            <LevelCheckBox level={'none'}><em>{t('no-download-access','No Download Access')}</em></LevelCheckBox>

            {/*<Divider style={{margin:'2em 0 .5em 0'}}/>*/}

            {/*<label>Watermark:</label>*/}
            {/*<AntSelect*/}
            {/*    onChange={changeWatermark}*/}
            {/*    value={filters.watermark}*/}
            {/*    style={{width:'100%'}}*/}
            {/*    disabled={!filters.download_levels}*/}
            {/*    allowClear*/}
            {/*>*/}
            {/*    <AntSelect.Option value={null}/>*/}
            {/*    <AntSelect.Option value={'true'}>Yes <Tag>{aggs ? aggs.download_level_watermark?.doc_count : <LoadingOutlined/>}</Tag></AntSelect.Option>*/}
            {/*    <AntSelect.Option value={'false'}>No <Tag>{aggs ? aggs.download_level_no_watermark?.doc_count : <LoadingOutlined/>}</Tag></AntSelect.Option>*/}
            {/*</AntSelect>*/}

            {(!!filters.download_levels?.length || filters.watermark) && (
                <Button size='small' icon={<CloseCircleOutlined/>} onClick={reset}>{t('reset','Reset')}</Button>
            )}
        </VerticalSpace>
    )
}

const ClickableTag = ({onClick, children,...props}) => {
    return (
        <Tag
            tabIndex={0}
            onClick={onClick}
            onKeyDown={e => e.keyCode === 13 && onClick()}
            {...props}
        >
            {children}
        </Tag>
    )
}

const FileExtFilter = ({})=>{
    const {filters, setFilters} = useFilters();
    const {aggs} = useAggsState()

    return (
        <Flex wrap={'wrap'} gap={'small'}>
            {aggs?.ext && aggs.ext.buckets.map((agg,i) => {
                const applied = filters.exts?.indexOf(agg.key) != -1

                const onClick = ()=>{
                    setFilters(applied, {exts: agg.key})
                }

                return  (
                    <ClickableTag
                        key={i}
                        color={applied ? 'blue' : null}
                        style={{cursor:'pointer'}}
                        onClick={onClick}
                    >
                        {agg.key} <Badge count={agg.doc_count} overflowCount={1000000} style={{backgroundColor:'#fff', color:'#999'}}/>
                    </ClickableTag>
                )
            })}
        </Flex>
    )
}

const ContainersFilter = ({})=>{
    const {t} = useTranslation();
    const currentUser = useCurrentUser()
    const {filters} = useFilters();
    const ability = useAbility(AbilityContext);

    return (
        <Collapse>
            {ability.can('view', 'StorageFolder') && (
                <Collapse.Panel
                    header={
                        <Space>
                            <StorageFolderIcon/> {t('file-vault','File Vault')}
                            {filters.storage_folder_ids && <Tag>On</Tag>}
                        </Space>
                    }
                    key={'storage-folder-filter'}
                >
                    <AssetGroupFilter type={'storage_folders'}/>
                </Collapse.Panel>
            )}

            <Collapse.Panel
                header={
                    <Space>
                        <LibraryIcon/> {t('libraries','Libraries')}
                        {filters.collection_ids && <Tag>On</Tag>}
                    </Space>
                }
                key={'libraries-filter'}
            >
                <AssetGroupFilter type={'collections'}/>
            </Collapse.Panel>

            {currentUser && (
                <Collapse.Panel
                    header={
                        <Space>
                            <User user={currentUser} iconOnly noPopover style={{marginRight: 5}} size={25}/> {t('my-projects','My Projects')}
                            {filters.lightbox_ids && <Tag>On</Tag>}
                        </Space>
                    }
                    key={'lightbox-filter'}
                >
                    <AssetGroupFilter type={'lightboxes'}/>
                </Collapse.Panel>
            )}

        </Collapse>
    )
}

const UploadedByFilter = ({refreshUploads})=>{
    const {t} = useTranslation();

    const {state: sessionState} = useContext(SessionContext);
    const {canUpload} = sessionState;

    return (
        <>
            <UserFilter/>

            {canUpload && (
                <>
                    <Divider>
                        <CloudUploadOutlined/> {t('my-uploads','My Uploads')}
                    </Divider>

                    <UploadsTree key={refreshUploads}/>
                </>
            )}
        </>
    )
}